/* Global container */
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Navbar styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
  color: white;
  padding: 15px 30px;
}

.nav-left {
  display: flex;
  align-items: center;
}

.nav-logo-title {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.title {
  font-size: 24px;
  margin: 0;
}

.nav-right {
  display: flex;
  gap: 20px;
}

.nav-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.nav-button:hover {
  background-color: #0056b3;
}

/* Content area */
.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10%; /* This will push the content down */
}

.center-button {
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
}

.center-button:hover {
  background-color: #0056b3;
}

/* Footer styling */
.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 16px;
}

/* Storybook specific styling */
.storybook {
  text-align: center;
  margin-top: 50px;
}

/* App container and main content */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Ensure routes take full height */
.main-content > div {
  flex: 1;
}

/* Add these styles to ensure proper layout on all devices */
* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

/*--- FANCY FRAME ---*/
.fancyFrame,
.shareModal {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: relative; /* Changed from absolute to relative */
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.fancyFrameContainer {
  background-color: #00244c;
  filter: drop-shadow(0px 0px 10px #000000);
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  grid-template-rows: 40px 1fr 40px;
  gap: 0;
  width: 100%;
  height: 100%;
  outline: 3px solid #eace94;
  outline-offset: -15px;
  overflow: hidden;
}

.fancyFrameTopLeftCorner {
  grid-column: 1;
  grid-row: 1;
}

.fancyFrameTopLeftCorner img {
  position: relative;
  left: 15px;
  top: 15px;
}

.fancyFrameTopRightCorner {
  grid-column: 3;
  grid-row: 1;
}

.fancyFrameTopRightCorner img {
  position: relative;
  transform: scaleX(-1);
  right: 94px;
  top: 15px;
}

.fancyFrameBottomLeftCorner {
  grid-column: 1;
  grid-row: 3;
}

.fancyFrameBottomLeftCorner img {
  position: relative;
  transform: scaleY(-1);
  left: 13px;
  bottom: 92px;
}

.fancyFrameBottomRightCorner {
  grid-column: 3;
  grid-row: 3;
}

.fancyFrameBottomRightCorner img {
  position: relative;
  transform: scale(-1, -1);
  right: 92px;
  bottom: 92px;
}

.fancyFrameContents {
  grid-column: 2;
  grid-row: 2;
  border-radius: 90px;
  container-type: inline-size;
  overflow: hidden;
  background-color: #ffffff;
  border: 7px solid #cc9b51;
}

.fancyFrame p {
  font-size: max(15px, 2.5cqi);
}
