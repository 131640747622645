/* header.css */

/* Header styles */
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 1rem;
    background-color: transparent;
}

.header-transparent {
    background-color: transparent;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffffff;
    text-decoration: none;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    white-space: nowrap;
}

.nav-links {
    display: flex;
}

.nav-links a {
    color: white;
    text-decoration: none;
    margin: 0 15px;
}

.sign-in-button {
    display: none;
}

.auth-buttons {
    display: flex;
    align-items: center;
}

.auth-buttons button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.auth-form {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.auth-form form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.auth-form input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.auth-form button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

@media (max-width: 768px) {
    .header-content {
        height: auto;
        padding: 10px;
    }

    .title {
        font-size: 1rem;
    }

    .logo {
        font-size: 1.2rem; /* Smaller font size on mobile */
    }
    
    .header-content {
        flex-wrap: wrap; /* Allows items to wrap on smaller screens */
    }

    .auth-buttons {
        margin-top: 10px; /* Add some space below the logo on small screens */
    }

    .auth-button {
        padding: 8px 15px; /* Slightly smaller padding on mobile */
        font-size: 0.9rem; /* Slightly smaller font on mobile */
    }
}

/* ... existing styles ... */

.logo-title {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: #8e44ad;
    text-decoration: none;
}

.checkmark {
    color: #4CAF50;
    font-size: 24px;
    margin-left: 5px;
}

.header-logo {
    height: 30px; /* Adjust this value to match your logo's size */
    margin-left: 10px; /* Space between the checkmark and the logo */
}

.welcome-message {
    color: #ffffff;
    margin-right: 1rem;
    font-size: 1rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.auth-button {
    padding: 0.5rem 1rem;
    margin-left: 0.5rem;
    border: none;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.auth-button:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

/* ... rest of your existing styles ... */

.header-solid {
  background-color: #007bff; /* Or any color that matches your design */
}
