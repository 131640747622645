.home-page {
    background-image: url('../assets/images/clouds.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: relative;
}

.home-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: linear-gradient(135deg, rgba(142, 68, 173, 0.8), rgba(155, 89, 182, 0.8)); */
}

.content-wrapper {
    position: relative;
    z-index: 1;
    text-align: center;
    color: white;
}

.hero-section h1 {
    font-size: 48px;
    margin-bottom: 20px;
}

.hero-section p {
    font-size: 18px;
    margin-bottom: 30px;
}

.cta-button {
    background-color: white;
    color: #447ead;
    border: none;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 25px;
    cursor: pointer;
}

.stats-section {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.stat-item {
    margin: 0 30px;
}

.stat-item h2 {
    font-size: 36px;
    margin-bottom: 5px;
}

.stat-item p {
    font-size: 14px;
}

.create-dream-life-button {
  background-color: #4CAF50;
  color: white;
  border: 2px solid #45a049; /* Add a border */
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  text-decoration: none; /* Remove underline from Link component */
  display: inline-block; /* Ensure proper sizing */
}

.create-dream-life-button:hover {
  background-color: #45a049;
  border-color: #3d8b3d; /* Darken border color on hover */
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.create-dream-life-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.create-dream-life-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: all 0.5s;
}

.create-dream-life-button:hover::before {
  left: 100%;
}
