.fancyFrame {
  width: 100%;
  height: 100%;
}

.fancyFrameContainer {
  width: 100%;
  height: 100%;
  background-color: #00244c;
  border: 2px solid #eace94;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.fancyFrameCorner {
  position: absolute;
  width: 40px;
  height: 40px;
  background-image: url('../assets/images/fancy_corner.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.fancyFrameCorner.top-left {
  top: 0;
  left: 0;
}

.fancyFrameCorner.top-right {
  top: 0;
  right: 0;
  transform: scaleX(-1);
}

.fancyFrameCorner.bottom-left {
  bottom: 0;
  left: 0;
  transform: scaleY(-1);
}

.fancyFrameCorner.bottom-right {
  bottom: 0;
  right: 0;
  transform: scale(-1);
}

.fancyFrameContents {
  background-color: #ffffff;
  border: 2px solid #cc9b51;
  border-radius: 15px;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 768px) {
  .fancyFrame {
    width: 95%;
    margin: 20px auto;
  }

  .fancyFrameCorner {
    width: 30px;
    height: 30px;
  }

  .fancyFrameContents {
    margin: 5px;
    padding: 10px;
  }
}

.fancyFrame p {
  font-size: max(15px, 2.5cqi);
}
