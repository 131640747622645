body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  min-height: 100vh;
  background-color: rgb(0, 126, 189);
}

.storybook-page {
  min-height: 100vh;
  background-color: rgb(0, 126, 189);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

.storybook-content {
  background-color: white;
  border-radius: 8px;
  padding: 30px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 4px solid #DAA520; /* Darker gold color (Goldenrod) */
}

.storybook-content h1 {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.storybook-description {
  font-size: 1rem;
  color: #666;
  text-align: center;
  margin-bottom: 30px;
}

.input-type-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.input-type-button {
  background-color: #f0f0f0;
  color: #333;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.input-type-button.active {
  background-color: #4CAF50;
  color: white;
}

.story-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 1rem;
  color: #333;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="email"] {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 40px; /* Explicitly set height for consistency */
  box-sizing: border-box; /* Ensures padding doesn't affect overall size */
}

.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group textarea {
  min-height: 100px;
  resize: vertical;
}

.form-group input[type="email"] {
  width: 100%;
  padding: 12px;
  font-size: 1.1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
}

/* If you want to specifically target the email field, you can use its id */
#email {
  height: 50px; /* Increased height */
}

.submit-button {
  background-color: #4CAF50;
  color: white;
  font-size: 1.1rem;
  padding: 12px 25px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: center;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .storybook-content {
    padding: 20px;
  }

  .storybook-content h1 {
    font-size: 1.8rem;
  }

  .storybook-description {
    font-size: 0.9rem;
  }

  .input-type-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

.page-background {
  min-height: 100vh;
  background-color: rgb(0, 126, 189);
  background-image: url('../assets/images/clouds.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 60px 20px;
}

.content-wrapper {
  width: 100%;
  max-width: 600px;
}

.storybook-content {
  color: #333;
}

.storybook-content h1 {
  margin-bottom: 20px;
}

.storybook-description {
  margin-bottom: 30px;
}

.story-form h2 {
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group select,
.form-group input[type="text"],
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.form-group textarea {
  resize: vertical;
}

.submit-container {
  text-align: center;
  margin-top: 20px;
}

.submit-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #45a049;
}

.submit-container {
  text-align: center;
  margin-top: 20px;
}

.submit-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #45a049;
}

.input-type-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.input-type-button {
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  background-color: #f0f0f0;
  border: 2px solid #4CAF50;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.input-type-button.active {
  background-color: #4CAF50;
  color: white;
}

.input-type-button:hover {
  background-color: #e0e0e0;
}

.input-type-button.active:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .storybook-page {
    padding-top: 50px;
  }

  .storybook-content {
    padding: 10px;
  }
}

.fancyFrame {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.fancyFrameContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.fancyFrameContents {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.dream-career-input {
  min-height: 80px;
  font-size: 16px;
  padding: 10px 15px;
  resize: vertical;
}

.dream-career-input {
  width: 100%;
  max-width: 500px;
}

@media (max-width: 768px) {
  .dream-career-input {
    height: 40px;
    font-size: 14px;
    padding: 8px 12px;
  }
}
