.story-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;  /* Changed from center to flex-start */
  align-items: center;
  padding: 120px 20px 20px;  /* Increased top padding from 80px to 120px */
  background-image: url('../assets/images/clouds.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.story-book {
  display: flex;
  flex-direction: column;
  background-color: #00244c;
  width: 90vw;
  max-width: 1200px;
  height: 70vh;
  max-height: 800px;
  position: relative;
  padding: 12px;
  border-radius: 15px;
  overflow: hidden;
}

.story-book::before,
.story-book::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border: 2px solid #eace94;
  border-radius: 12px;
  pointer-events: none;
}

.story-book::after {
  top: 12px;
  left: 12px;
  right: 12px;
  bottom: 12px;
  border-color: #cc9b51;
  border-radius: 8px;
}

.chapter-title {
  text-align: center;
  color: #00244c;
  margin-bottom: 15px;
  font-size: 22px;  /* Slightly reduced from 24px to accommodate longer titles */
  line-height: 1.3;  /* Added for better spacing if title wraps to two lines */
  padding: 0 10px;  /* Added some padding for better mobile display */
}

.pages-container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.page {
  flex: 1;
  background-color: white;
  margin: 10px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-page, .text-page {
  flex: 1;
}

.page-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  overflow-y: auto;
}

.image-content {
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-content img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.image-placeholder {
  font-size: 20px;
  color: #999;
  text-align: center;
}

.page-content p {
  font-size: 16px;  /* Increased from 14px */
  color: #5a3e2b;
  line-height: 1.5;  /* Slightly increased for better readability */
  text-align: left;
  width: 100%;
}

.navigation-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.navigation-buttons button {
  padding: 10px 20px;
  font-size: 16px;
  background: linear-gradient(145deg, #51b354, #45a049);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(76, 175, 80, 0.2);
}

.navigation-buttons button:hover:not(:disabled) {
  background: linear-gradient(145deg, #45a049, #3d8b40);
  transform: translateY(-1px);
  box-shadow: 0 4px 15px rgba(76, 175, 80, 0.3);
}

.navigation-buttons button:disabled {
  background: linear-gradient(145deg, #cccccc, #bbbbbb);
  cursor: not-allowed;
  box-shadow: none;
}

.navigation-buttons span {
  font-size: 16px;
  color: #333;
  font-weight: 500;
}

@media (max-width: 768px) {
  .story-container {
    padding-top: 80px;  /* Adjusted for mobile screens */
  }

  .story-book {
    height: auto;
    max-height: none;
  }

  .pages-container {
    flex-direction: column;
  }

  .page {
    margin: 10px 0;
  }

  .image-page {
    height: 50vh;
    flex: none;
  }

  .text-page {
    height: auto;
    flex: none;
  }

  .image-content img {
    max-height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .chapter-title {
    font-size: 20px;  /* Increased from 18px */
  }

  .page-content p {
    font-size: 14px;  /* Increased from 12px */
  }

  .navigation-buttons {
    gap: 15px;
  }

  .navigation-buttons button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .navigation-buttons span {
    font-size: 14px;
  }
}

.chapter-content p {
  margin-bottom: 15px;
}

.chapter-content p:last-child {
  margin-bottom: 0;
}

.empty-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 20px;
}

.empty-page p {
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
}

.empty-page p:first-child {
  font-weight: bold;
  color: #4CAF50;
  font-size: 22px;
}

.final-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  overflow-y: auto;
  padding-top: 20px; /* Add some padding at the top */
}

.final-page-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 85%;
  gap: 16px;
  margin-bottom: 20px;
}

.final-page-button {
  padding: 20px;
  font-size: 18px;
  background: linear-gradient(145deg, #51b354, #45a049);
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: left;
  white-space: normal;
  height: auto;
  min-height: 80px;
  line-height: 1.4;
  box-shadow: 0 4px 15px rgba(76, 175, 80, 0.2);
  position: relative;
  overflow: hidden;
}

.final-page-button:hover {
  background: linear-gradient(145deg, #45a049, #3d8b40);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(76, 175, 80, 0.3);
}

.final-page-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 10px rgba(76, 175, 80, 0.2);
}

.final-page-button div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.final-page-button div > div {
  font-size: 0.85em;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.4;
}

@media (max-width: 768px) {
  .final-page-buttons {
    width: 92%;
    gap: 12px;
  }

  .final-page-button {
    padding: 16px;
    font-size: 16px;
    min-height: 70px;
    border-radius: 10px;
  }

  .final-page-button div > div {
    font-size: 0.8em;
  }

  .navigation-buttons {
    gap: 15px;
  }

  .navigation-buttons button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .navigation-buttons span {
    font-size: 14px;
  }
}

.story-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: white;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .story-title {
    font-size: 2rem;
  }
}

.loading-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 18px;
  color: #666;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 30px 20px 20px; /* Increased top padding to accommodate close button */
  border-radius: 10px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  position: relative; /* Added to position the close button */
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  padding: 0;
  line-height: 1;
}

.popup-close:hover {
  color: #4CAF50;
}

.popup-content h2 {
  color: #4CAF50;
  margin-bottom: 20px;
}

.popup-content ol, .popup-content ul {
  padding-left: 20px;
  margin-bottom: 20px;
}

.popup-content li {
  margin-bottom: 10px;
}

.popup-content p {
  margin-bottom: 15px;
}

.future-features {
  color: #4CAF50;
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: center;
}

.get-access-text {
  color: #4CAF50;
  font-size: 1.1rem;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.tally-form-container {
  width: 90%;
  max-width: 500px; /* Limit the maximum width */
  margin: 0 auto;
  transform: scale(0.9); /* Scale down the container */
  transform-origin: top center; /* Scale from the top center */
}

.tally-form-container iframe {
  width: 111%; /* Increase width to compensate for scaling */
  height: 333px; /* Increase height to compensate for scaling */
  border: none;
  transform: scale(0.9); /* Scale down the iframe */
  transform-origin: top left; /* Scale from the top left */
}
